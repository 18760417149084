import ReactDOM from "react-dom"
import { Fragment, useCallback, useEffect, useRef } from "react"

import SVGIcon from "../../components/ui/SVGIcon"
import ProductCard from "../../components/product-card/ProductCard"
import SwiperWrapper from "../../components/Swiper"
import { App } from "~/types"
import Swiper from "swiper"

type Props = {
	onClose: () => void
	defaultActiveIndex: number
	products: Array<{
		product: App.StorefrontAPI.Product<false>
		hotspot: {
			value: string
		}
	}>
}
const MobileShopTheLookProductsSlider = (props: Props) => {
	const zIndex = 1000000
	const swiperRef = useRef<null | Swiper>(null)

	const containerRef = useRef<null | HTMLDivElement>(null)

	const { onClose } = props
	const closeCb = useCallback(() => {
		containerRef.current!.style.transform = `translate(0, 100%)`
		setTimeout(() => {
			onClose()
		}, 125)
	}, [onClose])

	useEffect(() => {
		if (props.defaultActiveIndex !== 0) {
			swiperRef.current?.slideTo(props.defaultActiveIndex)
		}
	}, [props.defaultActiveIndex])

	useEffect(() => {
		const current = containerRef.current
		if (current == null) {
			return
		}

		requestAnimationFrame(() => {
			current.style.transform = `translate(0, 0)`
		})
	}, [])

	return ReactDOM.createPortal(
		<Fragment>
			<div
				onClick={closeCb}
				style={{
					display: "block",
					position: "fixed",
					zIndex: zIndex - 1,
					left: 0,
					bottom: 0,
					top: 0,
					right: 0,
					height: "100%",
					width: "100vw",
					backgroundColor: "rgba(0,0,0,0.4)"
				}}
			/>
			<div
				ref={containerRef}
				style={{
					position: "fixed",
					zIndex: zIndex,
					bottom: 0,
					right: 0,
					height: "50%",
					width: "100vw",
					maxWidth: 500,
					// overflowY: 'auto',
					boxShadow: "1px 1px 15px rgba(0,0,0,0.2)",
					backgroundColor: "#fff",
					transition: "transform .1s cubic-bezier(.17,.67,.83,.67)",
					transform: `translate(0, 100%)`
				}}>
				<div
					onClick={closeCb}
					className="flex justify-end">
					<SVGIcon name="x" />
				</div>
				<div
					className="px-[10px]"
					style={{
						overflowX: "auto",
						height: `calc(100%)`
					}}>
					<SwiperWrapper
						swiperOptions={{}}
						apiRef={(swiper) => {
							swiperRef.current = swiper
						}}
						id={`ShopTheLook-slider`}>
						{props.products.map((product, i) => {
							return (
								<div
									key={i}
									className="max-w-[180px] mx-auto">
									<ProductCard product={product.product} />
								</div>
							)
						})}
					</SwiperWrapper>
				</div>
			</div>
		</Fragment>,
		document.body
	)
}

export default MobileShopTheLookProductsSlider
