import { Suspense } from "react"
import ProductCard from "../components/product-card/ProductCard"
import { CMS } from "./CMS"
import { Await } from "@remix-run/react"
import { storefrontAPI } from "../shared/storefront-api/StorefrontAPI"
import { productCardProductRequest } from "../routes/($lang)/getPageTemplateMetaObjects"
import { Link } from "../components"
import { CollectionSwiperSectionSchema } from "./CollectionSwiper.schema"

export type CollectionSwiperSectionProps = {
	fields: CMS.SectionPropsFields<typeof CollectionSwiperSectionSchema>
	deferred: ReturnType<typeof getCollectionForCollectionSwiper>
}

export async function getCollectionForCollectionSwiper(
	args: CMS.LoaderArgs<CollectionSwiperSectionProps["fields"]>
) {
	const { collection } = args.fields

	if (collection.reference == null) {
		return null
	}

	return storefrontAPI
		.query({
			collection: [
				{
					handle: collection.reference.handle
				},
				{
					title: true,
					products: [
						{
							first: 10
						},
						{
							nodes: {
								...productCardProductRequest
							}
						}
					]
				}
			]
		})
		.then((res) => {
			return res.data?.collection
		})
}

const CollectionSwiper = (props: CollectionSwiperSectionProps) => {
	const { title, button_text, collection } = props.fields
	const collectionHandle = collection.reference?.handle
	const emptyArrayForSkeleton = [1, 2, 3, 4, 5]

	return (
		<div className="container space-y-4">
			<div className="flex items-center justify-between">
				<h3>{title.value}</h3>
				<Link
					style={{
						boxShadow: "1px 1px 10px rgba(0,0,0,0.3)"
					}}
					className="button-collection"
					to={`/collections/${collectionHandle}`}>
					{button_text?.value}
				</Link>
			</div>
			<Suspense
				fallback={
					<div className="space-y-4 container">
						<div
							className="skeleton-box"
							style={{
								height: 40,
								width: 200
							}}
						/>

						<div className="flex space-x-4 overflow-x-auto">
							{emptyArrayForSkeleton.map((i) => {
								return (
									<div
										key={i}
										className="skeleton-box flex-shrink-0"
										style={{
											width: 297,
											height: 489
										}}
									/>
								)
							})}
						</div>
					</div>
				}>
				<Await
					resolve={props.deferred}
					errorElement={<p>Error loading recommended products!</p>}>
					{(deferred) => {
						if (deferred == null) {
							return <div>Collection not found!</div>
						}
						return (
							<div className="flex product-cards-list-container scroller pb-2 overflow-x-auto">
								{deferred.products.nodes.map((product, i) => {
									return (
										<div
											key={i}
											className="flex-shrink-0 product-card-wrapper">
											<ProductCard
												product={{
													...product,
													variants: product.variants.nodes,
													images: product.images.nodes
												}}
											/>
										</div>
									)
								})}
							</div>
						)
					}}
				</Await>
			</Suspense>
		</div>
	)
}

export default CollectionSwiper
