import { Suspense, useRef } from "react"
import Swiper from "swiper"
import TheLookView from "./TheLookView"
import SwiperWrapper from "../../components/Swiper"
import SVGIcon from "~/components/ui/SVGIcon"
import { App } from "~/types"
import { CMS } from "../CMS"
import { storefrontAPI } from "../../shared/storefront-api/StorefrontAPI"
import { productCardProductRequest } from "../../routes/($lang)/getPageTemplateMetaObjects"
import { Flattened, flattenShopifyObject } from "../../shared/utils"
import { Await } from "@remix-run/react"
import { ShopTheLookSectionSchema } from "./ShopTheLookSection.schema"

export function getShopTheLookBlocks(args: CMS.LoaderArgs<ShopTheLookProps["fields"]>) {
	return Promise.all(
		args.fields.blocks.map(async (blockField) => {
			const { fields } = blockField
			const productsIDs: Array<string> = []

			productsIDs.push(fields.product_1.value)
			if (fields.product_2 != null) {
				productsIDs.push(fields.product_2.value)
			}
			const productsFromQuery = await getProductsForShopTheLookBlock({
				productsIDs: productsIDs
			})

			const products: Array<{
				product: Flattened<App.StorefrontAPI.Product<true>>
				hotspot: {
					value: string
				}
			}> = []

			productsFromQuery.forEach((product, i) => {
				const count = (i + 1) as 1 | 2
				const hotspot = fields[`product_${count}_hotspot_xy`] || {
					value: "top:0;left:0;"
				}
				if (product != null) {
					products.push({ product: flattenShopifyObject(product), hotspot: hotspot })
				}
			})

			return {
				products,
				imageUrl: fields.image.reference!.image.url
			}
		})
	)
}

export type ShopTheLookProps = {
	fields: CMS.SectionPropsFields<typeof ShopTheLookSectionSchema>
	deferred: ReturnType<typeof getShopTheLookBlocks>
}

export type TheLookBlock = {
	imageUrl: string
	products: Array<{
		product: App.StorefrontAPI.Product<false>
		hotspot: {
			value: string
		}
	}>
}

export async function getProductsForShopTheLookBlock(props: {
	productsIDs: Array<string>
}) {
	const products: Array<null | App.StorefrontAPI.Product<true>> = await Promise.all(
		props.productsIDs.map(async (productID) => {
			return storefrontAPI
				.query({
					product: [
						{
							id: productID
						},
						{
							...productCardProductRequest
						}
					]
				})
				.then((res) => {
					return res.data?.product!
				})
		})
	)
	return products
}

const ShopTheLook = (props: ShopTheLookProps) => {
	const swiperRef = useRef<null | Swiper>(null)
	const { section_title } = props.fields

	return (
		<div className="container space-y-6">
			<h3 className="text-center">{section_title.value}</h3>
			<Suspense fallback={<div>loading</div>}>
				<Await resolve={props.deferred}>
					{(blocks) => {
						if (blocks == null) {
							return <div>No Blocks Found</div>
						}
						return (
							<SwiperWrapper
								id="ShopTheLook"
								swiperOptions={{
									slidesPerViewDesktop: 1,
									slidesPerViewMobile: 1.1
								}}
								apiRef={(swiper) => {
									swiperRef.current = swiper
									// swiper.el.querySelectorAll(".go-prev").forEach((elem) => elem.addEventListener("click", swiper.slidePrev))
									// swiper.el.querySelectorAll(".go-next").forEach((elem) => elem.addEventListener("click", swiper.slideNext))
								}}>
								{blocks.map((block, theLookIndex) => {
									return (
										<TheLookView
											swiperRef={swiperRef}
											key={theLookIndex}
											block={block}
											theLookIndex={theLookIndex}
											renderParentControls={() => {
												return (
													<>
														<div
															onClick={() => {
																swiperRef.current!.slidePrev()
															}}
															className="go-prev"
															style={{
																left: 10,
																zIndex: 10,
																top: "50%",
																cursor: "pointer",
																position: "absolute",
																transform: "translate(0, -50%)"
															}}>
															<div
																className="p-1"
																style={{
																	backgroundColor: "#fff",
																	borderRadius: "50%"
																}}>
																<SVGIcon
																	name="chevron-left"
																	color="#ef6f6c"
																	strokeWidth={3}
																	size={20}
																/>
															</div>
														</div>
														<div
															onClick={() => {
																swiperRef.current!.slideNext()
															}}
															className="go-next"
															style={{
																top: "50%",
																right: 10,
																zIndex: 10,
																cursor: "pointer",
																position: "absolute",
																transform: "translate(0, -50%)"
															}}>
															<div
																className="p-1"
																style={{
																	backgroundColor: "#fff",
																	borderRadius: "50%"
																}}>
																<SVGIcon
																	name="chevron-right"
																	color="#ef6f6c"
																	strokeWidth={3}
																	size={20}
																/>
															</div>
														</div>
													</>
												)
											}}
										/>
									)
								})}
							</SwiperWrapper>
						)
					}}
				</Await>
			</Suspense>
		</div>
	)
}

export default ShopTheLook
