import SVGIcon from "../components/ui/SVGIcon"
import type { CMS } from "./CMS"

export const FeaturedCollectionsSectionSchema = {
	sectionName: "Featured Collections",
	fields: {
		section_title: {
			description: "Section Title",
			required: false,
			type: "single_line_text_field"
		},
		is_grid: {
			description: "Grid",
			required: true,
			type: "boolean"
		},
		blocks: {
			description: "Blocks",
			required: true,
			type: "list.metaobject_reference",
			fields: {
				title: {
					required: false,
					type: "single_line_text_field"
				},
				collection: {
					description: "Collection",
					required: true,
					type: "collection_reference"
				},
				collection_image: {
					description: "Collection Image",
					required: true,
					type: "file_reference",
					validations: [
						{
							name: "file_type_options",
							value: '["Image"]'
						}
					]
				},
				is_full_height: {
					description: "Is Full Height",
					required: true,
					type: "boolean"
				},
				button_text: {
					description: "Button Text",
					required: false,
					type: "single_line_text_field"
				}
			}
		}
	}
} satisfies CMS.Schema

type Props = {
	fields: CMS.SectionPropsFields<typeof FeaturedCollectionsSectionSchema>
}

const FeaturedCollections = (props: Props) => {
	const { is_grid, section_title, blocks } = props.fields

	let sectionClassName = "grid grid-flow-row md:grid-flow-col md:grid-rows-4 gap-6"

	if (blocks.length > 1) {
		sectionClassName = `${sectionClassName} md:h-[90vh]`
	}

	if (is_grid.value === "false") {
		sectionClassName =
			"scroller flex text-center items-center space-x-6 overflow-auto pb-4"
	}

	return (
		<div className="container space-y-6">
			{section_title != null && (
				<h3 className="text-center"> {section_title.value}</h3>
			)}
			<div className={`${sectionClassName}`}>
				{blocks.map((block, i) => {
					const {
						title,
						collection,
						collection_image,
						is_full_height,
						button_text
					} = block.fields

					let rowSpan = "row-span-2"
					if (is_full_height.value === "true") {
						rowSpan = "row-span-2 md:row-span-4"
					}
					let blockClassName = `col-span-2 md:col-span-6 ${rowSpan}`
					if (is_grid.value === "false") {
						blockClassName = "w-[200px] md:w-[20%] flex-shrink-0 flex-grow"
					}

					if (collection.reference == null || collection_image.reference == null) {
						return null
					}

					return (
						<a
							key={i}
							className={`rounded-card ${blockClassName} block`}
							style={{
								zIndex: 1,
								overflow: "hidden",
								position: "relative"
							}}
							href={`/collections/${collection.reference.handle}`}>
							<img
								className="hover:scale-[1.05]"
								style={{
									width: "100%",
									height: "100%",
									objectFit: "cover",
									transition: "transform .5s ease-in-out",
									aspectRatio:
										is_grid.value === "false" && collection_image.reference
											? collection_image.reference.image.width /
											  collection_image.reference.image.height
											: "unset"
								}}
								src={collection_image.reference.image.url}
							/>
							<div
								className="featured-collection-content space-y-2 text-right"
								style={{
									bottom: 20,
									right: 20,
									position: "absolute",
									zIndex: 5
								}}>
								{title != null && (
									<h3
										className="py-3"
										style={{
											color: "white"
										}}>
										{title.value}
									</h3>
								)}

								{button_text != null && (
									<button
										className="button frosted-bg inline-flex items-center justify-between space-x-2"
										style={{
											paddingRight: 12,
											paddingLeft: 12
										}}>
										<div className="text-sm md:text-base text-white">
											{button_text.value}
										</div>
										<SVGIcon
											color="white"
											name="arrow-narrow-right"
										/>
									</button>
								)}
							</div>
						</a>
					)
				})}
			</div>
		</div>
	)
}

export default FeaturedCollections
