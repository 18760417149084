import cn from "classnames"
import { ReactNode, Suspense, useCallback, useEffect, useRef, useState } from "react"
import Swiper from "swiper"
import ProductCard from "../../components/product-card/ProductCard"
import SwiperWrapper from "../../components/Swiper"

import MobileShopTheLookProductsSlider from "./MobileSTLProductsSlider"
import { TheLookBlock } from "./ShopTheLook"

type Props = {
	theLookIndex: number
	swiperRef: React.MutableRefObject<Swiper | null>
	renderParentControls: () => ReactNode
	block: TheLookBlock
}

const isMobile = () => {
	return (
		window.matchMedia("(pointer: coarse) and (max-width: 1024px)").matches === true ||
		window.matchMedia("(max-width: 767px)").matches === true
	)
}

const TheLookView = (props: Props) => {
	const swiperRef = useRef<null | Swiper>(null)

	const [state, setState] = useState({
		showMobile: false,
		activeProductIndex: 0
	})

	const onClickHotspotCb = useCallback((opts: { index: number }) => {
		setState({
			showMobile: isMobile() === true,
			activeProductIndex: opts.index
		})

		swiperRef.current?.slideTo(opts.index)
	}, [])

	const apiRefCb = useCallback((swiper: Swiper) => {
		swiperRef.current = swiper
	}, [])

	const onCloseMobileSTLCb = useCallback(() => {
		setState((prevState) => {
			prevState.showMobile = false
			return {
				...prevState
			}
		})
	}, [])

	return (
		<div
			key={props.theLookIndex}
			className="shop-the-look md:flex items-center space-x-12"
			style={{
				backgroundColor: "rgb(239, 239, 239)",
				borderRadius: "12px"
			}}>
			<div className="md:w-1/2 relative">
				{props.renderParentControls()}

				{props.block.products.map((product, i) => {
					const isActive = i === state.activeProductIndex
					const hotspotId = `HotSpot--${i}--${props.theLookIndex}`
					return (
						<div
							onClick={() => {
								onClickHotspotCb({ index: i })
							}}
							key={i}
							className={cn("shop-the-look-hotspot", {
								active: isActive
							})}
							id={hotspotId}
							style={{
								position: "absolute",
								cursor: "pointer",
								color: "red",
								fontWeight: "bolder"
							}}>
							<style
								dangerouslySetInnerHTML={{
									__html: `
									#${hotspotId} {
										${product.hotspot.value}
									}								
									`
								}}
							/>
							<div className="pulse">
								<div
									className="transform-centered"
									style={{
										width: 2,
										height: 2,
										backgroundColor: "#fff",
										borderRadius: "100%"
									}}
								/>
							</div>
						</div>
					)
				})}

				<img
					style={{
						borderRadius: "12px",
						width: "100%",
						objectFit: "contain"
					}}
					src={props.block?.imageUrl}
				/>
			</div>

			<div className="md:w-1/2">
				<SwiperWrapper
					swiperOptions={{
						slidesPerViewDesktop: 1
					}}
					apiRef={apiRefCb}
					id={`ShopTheLook--${props.theLookIndex}`}>
					{props.block.products.map((product, i) => {
						return (
							<div
								key={i}
								className="md:max-w-[250px] md:mx-auto hidden md:block">
								<ProductCard product={product.product} />
							</div>
						)
					})}
				</SwiperWrapper>
			</div>

			{state.showMobile === true && (
				<MobileShopTheLookProductsSlider
					defaultActiveIndex={state.activeProductIndex}
					onClose={onCloseMobileSTLCb}
					products={props.block.products}
				/>
			)}
		</div>
	)
}

export default TheLookView
