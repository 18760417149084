import { SerializeFrom } from "@shopify/remix-oxygen"
import { CMS } from "../../sections/CMS"
import CollectionSwiper from "../../sections/CollectionSwiper"
import FeaturedCollections from "../../sections/FeaturedCollections"
import HeroSliderSection from "../../sections/HeroSliderSection"
import MultiColumnSection from "../../sections/MultiColumnSection"
import TextWithIcon from "../../sections/TextWithIcon"
import ShopTheLook from "../../sections/shop-the-look/ShopTheLook"

const renderPageSections = (props: {
	data: SerializeFrom<{
		metaobject: { fields: CMS.MetaobjectField[] }
		[key: `deferred_${number}`]: {
			fields: CMS.SectionPropsFields<any>
		}
	}>
	sections: CMS.ParsedSections
}) => {
	return (
		<div className="space-y-10 md:space-y-18 mt-4 md:mt-8">
			{props.sections.map((section, i) => {
				if (section.type === "section_hero_slider") {
					return (
						<HeroSliderSection
							key={i}
							id={`HeroSlider-${i}`}
							fields={section.fields}
						/>
					)
				} else if (section.type === "section_collection_swiper") {
					const deferred =
						section.deferred_key == null
							? null
							: (props.data[section.deferred_key] as any)
					return (
						<CollectionSwiper
							key={i}
							fields={section.fields}
							deferred={deferred}
						/>
					)
				} else if (section.type === "section_multi_column") {
					return (
						<MultiColumnSection
							key={i}
							fields={section.fields}
						/>
					)
				} else if (section.type === "section_shop_the_look") {
					const deferred =
						section.deferred_key == null
							? null
							: (props.data[section.deferred_key] as any)
					return (
						<ShopTheLook
							key={i}
							fields={section.fields}
							deferred={deferred}
						/>
					)
				} else if (section.type === "section_featured_collections") {
					return (
						<FeaturedCollections
							key={i}
							fields={section.fields}
						/>
					)
				} else if (section.type === "section_text_with_icon") {
					return (
						<TextWithIcon
							key={i}
							fields={section.fields}
						/>
					)
				} else {
					const _t: never = section
				}
			})}
		</div>
	)
}
export default renderPageSections
