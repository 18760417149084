import SwiperWrapper from "../components/Swiper"
import { CMS } from "./CMS"
import { TextWithIconSectionSchema } from "./TextWithIcon.schema"

type TextWithIconProps = {
	fields: CMS.SectionPropsFields<typeof TextWithIconSectionSchema>
}

const TextWithIcon = (props: TextWithIconProps) => {
	return (
		<div className="container">
			<div
				style={{
					borderRadius: "18px",
					boxShadow: "4px 4px 8px #ccc"
				}}
				className="py-4 px-4">
				<SwiperWrapper
					paginationClassName="md:hidden"
					renderPagination={true}
					swiperOptions={{
						slidesPerViewDesktop: 3,
						slidesPerViewMobile: 1,
						spaceBetween: 20
					}}>
					{props.fields.blocks.map((block, i) => {
						const { image, text } = block.fields
						return (
							<div
								key={i}
								style={{
									marginTop: 20,
									marginBottom: 40
								}}>
								{image.reference != null && (
									<img
										style={{
											maxWidth: 40,
											margin: "0 auto",
											aspectRatio:
												image.reference.image.width /
												image.reference.image.height
										}}
										src={image.reference.image.url}
									/>
								)}
								{text != null && (
									<div className="text-xs lg:text-sm mt-2 text-center font-bold">
										{text.value}
									</div>
								)}
							</div>
						)
					})}
				</SwiperWrapper>
			</div>
		</div>
	)
}

export default TextWithIcon
