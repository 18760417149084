import { CMS } from "./CMS"

export const MultiColumnSectionSchema = {
	sectionName: "Multi Column",
	fields: {
		title: {
			description: "Section Title",
			required: false,
			type: "single_line_text_field"
		},
		blocks: {
			type: "list.metaobject_reference",
			required: false,
			fields: {
				text: {
					required: false,
					type: "single_line_text_field"
				},
				image: {
					description: "Image",
					required: false,
					type: "file_reference",
					validations: [
						{
							name: "file_type_options",
							value: '["Image"]'
						}
					]
				},
				collection: {
					description: "Collection",
					required: false,
					type: "collection_reference"
				},
				page: {
					required: false,
					type: "page_reference"
				},
				product: {
					description: "Product",
					required: false,
					type: "product_reference"
				}
			}
		}
	}
} satisfies CMS.Schema

export type MultiColumnSectionProps = {
	fields: CMS.SectionPropsFields<typeof MultiColumnSectionSchema>
}

const MultiColumnSection = (props: MultiColumnSectionProps) => {
	const { title } = props.fields
	return (
		<div className="container space-y-6">
			<h3 className="text-center">{title.value}</h3>
			<div className="flex overflow-x-auto space-x-6 scroller">
				{props.fields.blocks.map((block, i) => {
					const { text, image, collection, page, product } = block.fields
					let url = ""
					if (page != null) {
						url = `/pages/${page.reference?.handle}`
					} else if (collection != null) {
						url = `/collections/${collection.reference?.handle}`
					} else if (product != null) {
						url = `/products/${product.reference?.handle}`
					}

					return (
						<a
							href={url}
							className="inline-block space-y-3 max-w-[31vw] md:w-[250px] flex-shrink-0"
							key={i}>
							{image.reference?.image.url != null && (
								<img
									src={image.reference.image.url}
									loading="lazy"
									style={{
										width: 250,
										aspectRatio: "1/1",
										borderRadius: "50%",
										objectPosition: "center",
										objectFit: "cover"
									}}
								/>
							)}
							{text != null && <div className="text-center">{text.value}</div>}
						</a>
					)
				})}
			</div>
		</div>
	)
}

export default MultiColumnSection
