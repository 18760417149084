import { Link } from "../components/ui/Link"
import { CMS } from "./CMS"

export type HeroSliderSectionProps = {
	fields: CMS.SectionPropsFields<typeof HeroSliderSectionSchema>
	className?: string
	id: string
}

import SwiperWrapper from "../components/Swiper"
import { HeroSliderSectionSchema } from "./HeroSliderSection.schema"

const HeroSliderSection = (props: HeroSliderSectionProps) => {
	return (
		<div
			id={props.id}
			className={`hero-slider-section container ${
				props.className != null ? props.className : ""
			}`}>
			<SwiperWrapper
				swiperOptions={{ autoHeight: true }}
				className={"rounded-card"}>
				{props.fields.blocks.map((block, i) => {
					const {
						title,
						collection,
						desktop_image,
						mobile_image,
						page,
						content_position,
						button_text,
						text_align,
						subtitle
					} = block.fields

					let desktopAspectRatio = 0
					if (desktop_image.reference != null) {
						desktopAspectRatio =
							desktop_image.reference.image.width /
							desktop_image.reference.image.height
					}
					let mobileAspectRatio = desktopAspectRatio

					if (mobile_image.reference != null) {
						mobileAspectRatio =
							mobile_image.reference.image.width /
							mobile_image.reference.image.height
					}

					const contentPosition = content_position?.value
						.toLowerCase()
						.replace(" ", "-")

					const textAlign = text_align?.value.toLowerCase().replace(" ", "-")

					return (
						<Link
							key={i}
							to={
								collection != null
									? `/collections/${collection.reference?.handle}`
									: page != null
									? `/pages/${page.reference?.handle}`
									: ``
							}
							className={`hero-slide block nth-${i}`}
							style={{
								width: "100%",
								position: "relative",
								aspectRatio: desktopAspectRatio
							}}>
							{desktop_image.reference != null && (
								<picture
									style={{
										top: 0,
										left: 0,
										zIndex: 1,
										position: "absolute"
									}}>
									<style
										dangerouslySetInnerHTML={{
											__html: `@media (max-width: 767px) {
										#${props.id} .hero-slide.nth-${i} {
											aspect-ratio: ${mobileAspectRatio} !important;
										}
									}`
										}}
									/>
									<source
										media="(min-width: 768px)"
										srcSet={desktop_image.reference.image.url}
									/>

									{mobile_image.reference != null && (
										<img src={mobile_image.reference.image.url} />
									)}
								</picture>
							)}
							<div
								className={`hero-slide-content ${contentPosition} ${textAlign}`}>
								<div
									style={{
										textShadow: "2px 2px 10px rgba(0,0,0,0.6)"
									}}>
									{title != null && (
										<h1
											style={{
												color: "#fff"
											}}>
											{title.value}
										</h1>
									)}
									{subtitle != null && <div>{subtitle.value}</div>}
									{button_text != null && (
										<div className="button frosted-bg mt-4 inline-block">
											{button_text.value}
										</div>
									)}
								</div>
							</div>
						</Link>
					)
				})}
			</SwiperWrapper>
		</div>
	)
}

export default HeroSliderSection
